
.main-logo {
  height: 60vmin;
  margin-top: 5vh;
}

.main-body {
  background-color: #3a0247;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;  
  /* justify-content: center; */
  text-align: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.main-link {
  color: #5cffb0;
  animation: colorTransition 2s infinite alternate;
} 

/* <uniquifier>: Use a unique and descriptive class name
<weight>: Use a value from 100 to 900
 */
.lexend-giga-bold {
  font-family: "Lexend Giga", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}


@keyframes colorTransition {
  0% {
    color: #5cffb0;
  }
  100% {
    color: #fff;
  }
} 
